<template>
	
</template>
<script>
	export default {
		data(){
			return{
				login : false,
				lang:this.$i18n.locale,
			}
		},
		mounted(){
			this.Check();
		},
		methods : {
			Check : function(){
				const api =  this.$store.state.api;
                const lang = this.lang;
                const no =  this.$route.query.no;
                const code =  this.$route.query.code;
                const sec_no =  this.$route.query.sec_no;
				this.$http.post(`${api}/common/checkEmail`,{no,code,sec_no}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.$alert(this.$t('certification.1')).then(
									()=>{
										this.$router.push({path:'/'+this.$i18n.locale+'/login'});
									}
								)
							}else if(res.data.code=='100'){
								this.$alert(this.$t('certification.2')).then(
									()=>{
										this.$router.push({path:'/'+this.$i18n.locale+'/'});
									}
								)
							}
						}

					}
				).catch(() => {});
			}
		}
	}
</script>
<style scoped>
	.main_balance_total span {display: inline-block;}
</style>