<template>
	
</template>
<script>
	export default {
		data(){
			return{
				login : false,
				lang:this.$i18n.locale,
			}
		},
		mounted(){
			this.Check();
		},
		methods : {
			Check : function(){
				const login =  this.$store.state.login;
				const lang = this.lang
				if(login ==false){
					this.$router.push({name:'login'})
				}else if(login ==true){
					this.$router.push({name:'dashboard'})
				}
			}
		}
	}
</script>
<style scoped>
	.main_balance_total span {display: inline-block;}
</style>