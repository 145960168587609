<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title">
				<!-- <img src="/assets/img/icon09.png" alt="아이콘" class="menu_icon"> -->
				<i class="far fa-bullhorn text-primary"></i>
				{{$t('notice.1')}}
			</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="notice_table03">
				<table style="width:100%" class="main_table02">
					<thead>
						<tr>
							<th>{{$t('notice.2')}}</th>
							<th>{{$t('notice.3')}}</th>
						</tr>
					</thead>
					<tbody>
						
						<tr v-for="(item, index) in noticeList" :key="'notice'+index" @click="GotoNotice(item.code)">
							<td>{{item.title}}</td>
							<td>{{item.date}}</td>
						</tr>
					</tbody>
					<tbody></tbody>
				</table>
			</div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>

<script>

const CryptoJS = require("crypto-js");

export default {
	data(){
		return{
			noticeList: [],
		}
	},
	mounted () {
		this.GetNoticeList();
	},
	methods : {
		GetNoticeList(){
			const lang = this.$i18n.locale;
			const body = {lang};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/board/GetNoticeList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							
							this.noticeList = body.list;

						}
					}

				}
			).catch(() => {});
		},
		
		GotoNotice : function(code){
			
			this.$router.push({path:'/'+this.$i18n.locale+'/notice/'+code}); 
		}
	}
}
</script>

<style>
</style>